<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base font-semibold text-center text-gray-400">Login Settings</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button router-link="/home/settings">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="flex flex-col h-full p-10">
                <ion-card class="w-72 mx-auto shadow-none">
                    <ion-card-header class="mb-10">
                        <div class="rounded-full bg-smcc-smcc-green h-20 w-20 mx-auto mb-4 flex items-center justify-center">
                            <span class="text-white text-3xl font-bold uppercase" v-if="user.display_name">{{ getInitials(user.display_name) }}</span>
                        </div>
                        <ion-card-title class="text-center text-lmd font-bold text-smcc-smcc-grey" v-if="!user.display_name">Your Name</ion-card-title>
                        <ion-card-title class="text-center text-lmd font-bold text-smcc-smcc-grey">{{ user.display_name }}</ion-card-title>
                    </ion-card-header>

                <p class="mb-6">Login with your existing savemoneycutcarbon.com acocunt. this app will sync your swap list with the website.</p>
                    <p style="font-size: 0.938rem;" class="text-xs">Username</p>
                    <input v-model="user.user_email" id="username" required class="px-2 py-2 w-full rounded border border-gray-300 mt-2.5" />

                    <ion-label position="stacked" style="font-size: 0.938rem;" class="text-xs">Password</ion-label>
                    <input type="password" value="********" id="password" required style="--padding-start: 0.5rem;" class="px-2 py-2 w-full rounded border border-gray-300 mt-2.5" />
                    <ion-button fill="clear" class="border-2 border-smcc-smcc-green text-smcc-smcc-green text-center block rounded my-6" @click="handleSignOut()">
                        <ion-icon :src="require('@/assets/Icons/arrow-back.svg')" class="h-6 w-6 py-6 px-1"></ion-icon> Log out
                    </ion-button>
                </ion-card>
            </div>
            <div class="shadow-inner h-4"></div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonImg, IonLabel,  IonCardTitle, IonButtons, modalController, IonIcon, IonCard, IonCardHeader } from '@ionic/vue';
import {mapActions} from "vuex";
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
import {get} from '@/store/storage';

export default {
    name: 'ExploreContainer',
    props: {
        name: String
    },
    components: { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonImg, IonLabel,  IonCardTitle, IonButtons, IonIcon, IonCard, IonCardHeader },
    data() {
        return {
            user: [],
            form: {
                username: "",
                password: ""
            }
        }
    },
    setup() {
        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: TutorialModal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal
        };
    },
    async created() {
        this.user = await this.getUser()
    },
    methods: {
        getInitials(str) {
            const matches = str.match(/\b(\w)/g);
            return matches.join('');
        },
        ...mapActions("auth", ["signOut", "refreshToken"]),
        refresh() {
            this.refreshToken();
        },
        async handleSignOut() {
            await this.signOut().then(() => {
                this.router.push("/login");
            });
        },
        async getUser() {
            return await get('user');
        }
    },

}
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

.button-inner{
    justify-content: space-between;
}
ul li::before {
    content : "\2022"; /* Adds the bullet */
    @apply text-smcc-smcc-green text-2xl
}
/*! purgecss start ignore */

/*! purgecss end ignore */
</style>
